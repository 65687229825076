import Dexie from "dexie";
import config from "../config";

class AppDatabase extends Dexie {
	public settings: Dexie.Table<Settings, string>;
	public errorLog: Dexie.Table<ErrorLog, number>;
	public events: Dexie.Table<ThisEvent, number>;
	public cycleCountTaskLists: Dexie.Table<CycleCountTaskList, number>;
	public imageCache: Dexie.Table<ImageCache, number>;
	public changeReasons: Dexie.Table<LotCountChangeReason, string>;
	public retailLocations: Dexie.Table<RetailLocations, string>;
	public retailCustomers: Dexie.Table<RetailCustomers, string>;
	public retailParentLocations: Dexie.Table<RetailLocations, string>;
	public retailProducts: Dexie.Table<Product, number>;
	public retailCounts: Dexie.Table<RetailCounts, number>;
	public retailImages: Dexie.Table<File, number>;
	public finishLocations: Dexie.Table<FinishLocations, string>;
	public finishParentLocations: Dexie.Table<FinishLocations, string>;
	public finishLots: Dexie.Table<FinishLots, number>;
	public finishCounts: Dexie.Table<FinishCounts, number>;
	public finishImages: Dexie.Table<File, number>;
	public finishProducts: Dexie.Table<Product, number>;
	public materialLocations: Dexie.Table<Tree, string>;
	public materialParentLocations: Dexie.Table<Tree, string>;
	public materialProducts: Dexie.Table<Product, number>;
	public materialOrders: Dexie.Table<OrderLine, number>;
	public materialLots: Dexie.Table<LotCount, number>;
	public materialCounts: Dexie.Table<MaterialCounts, number>;
	public materialQueue: Dexie.Table<MaterialQueue, number>;
	public translations: Dexie.Table<Translation, string>;
	public constructor() {
		super("InventoryAppDB");
		//Do not remove/change primary keys unless deleting app db on start
		this.version(config.dexieVersion).stores({
			settings: "",
			errorLog: "++id",
			events: "id,_Id",
			cycleCountTaskLists: "id,_Id",
			imageCache: "++id,timestamp",
			changeReasons: "id,_Id",
			retailLocations: "id,[nodeTypeId+selfId]",
			retailCustomers: "id,referenceId,digitalTicket",
			retailParentLocations: "id,nodeLevel",
			retailProducts: "id,UPC",
			retailCounts: "++id,idempotentKey",
			retailImages: "++id",
			finishLocations: "id,nodeLevel",
			finishParentLocations: "id,nodeLevel",
			finishLots: "id,_Id",
			finishCounts: "++id",
			finishImages: "++id",
			finishProducts: "id,referenceId,_Id",
			materialLocations: "id,nodeLevel",
			materialParentLocations: "id,nodeLevel",
			materialProducts: "id,referenceId,_Id",
			materialOrders: "id",
			materialLots: "id,_Id",
			materialCounts: "++id",
			materialQueue: "++Id",
			translations: "[key+language],language",
		});
		this.settings = this.table("settings");
		this.errorLog = this.table("errorLog");
		this.events = this.table("events");
		this.cycleCountTaskLists = this.table("cycleCountTaskLists");
		this.imageCache = this.table("imageCache");
		this.changeReasons = this.table("changeReasons");
		this.retailLocations = this.table("retailLocations");
		this.retailCustomers = this.table("retailCustomers");
		this.retailParentLocations = this.table("retailParentLocations");
		this.retailProducts = this.table("retailProducts");
		this.retailCounts = this.table("retailCounts");
		this.retailImages = this.table("retailImages");
		this.finishLocations = this.table("finishLocations");
		this.finishParentLocations = this.table("finishParentLocations");
		this.finishLots = this.table("finishLots");
		this.finishCounts = this.table("finishCounts");
		this.finishImages = this.table("finishImages");
		this.finishProducts = this.table("finishProducts");
		this.materialLocations = this.table("materialLocations");
		this.materialParentLocations = this.table("materialParentLocations");
		this.materialProducts = this.table("materialProducts");
		this.materialLots = this.table("materialLots");
		this.materialOrders = this.table("materialOrders");
		this.materialCounts = this.table("materialCounts");
		this.materialQueue = this.table("materialQueue");
		this.translations = this.table("translations");
	}
}

const db = new AppDatabase();

db.settings.toArray().catch("VersionError", (e) => {
	// Failed with VersionError
	console.error("Version error: " + e.message);
});

export default db;
